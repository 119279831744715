import Alpine from "alpinejs";

const navigationSetup = () => {
    Alpine.store('navSetup', {
        isOpen: false,
        currentSidebarTab: null,
        isSettingsPanelOpen: false,
        isSubHeaderOpen: false,
        hasScrolled: false,
        isLight: Alpine.$persist(false),
        scrolling(el) {
            this.hasScrolled = (el.scrollTop > 50 ? true : false)
        },
        toggle(){
            this.isOpen = !this.isOpen;
        }
    });
}

export default navigationSetup;