import Router from './util/Router';
import common from './routes/common';
import navigationSetup from "./util/navigation";
import searchSetup from "./util/search";
import login from "./account/login";
import {profile} from "./account/profile";
import GoTrue from "gotrue-js";
import userDetails from "./account/user-details";

window.auth = new GoTrue({
    APIUrl: process.env.API_ROOT,
    audience: '',
    setCookie: true
});

const routes = new Router({
    common
})

document.addEventListener('alpine:init', () => {
    profile()
    login();
    userDetails()

    navigationSetup();
    searchSetup();

    routes.loadEvents();
});