import './scss/main.scss'

/* ===========================================================================
 Font-Awesome
 =========================================================================== */

import {library, dom} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {far} from '@fortawesome/free-regular-svg-icons'
import {fab} from '@fortawesome/free-brands-svg-icons'

library.add(fas, far, fab)

dom.i2svg();

dom.watch();

/* ===========================================================================
 Application JS
 =========================================================================== */
import "./js/app";

/* ===========================================================================
 Init AlpineJS
 =========================================================================== */
import "@kingshott/iodine";

import Alpine from 'alpinejs'
import persist from '@alpinejs/persist'
window.Alpine = Alpine;
Alpine.plugin(persist);

Alpine.start();





